<template>
    <div class="log-center-section">
            <Breadcrumb />

            <div class="card-log-center">
                <div class="card-header-log-center"
                    :style="{ background: active ? '#E8F5E9' : '#FFEBEE', color: active ? '#1B5E20' : '#B71C1C' }">
                    {{ active ? $t('LogCenter.SelfHostedRunning') : $t('LogCenter.SelfHostedOffline') }}
                </div>

                <div class="card-log-center-title">
                    <span>
                        {{ $t('Log Center') }}
                    </span>

                    <div class="card-title-actions">
                        <b-button class="btn-title-action" @click="refreshData">
                            <ReloadIcon class="reload-icon" />
                            {{ $t('IntegrationPage.Refresh') }}
                        </b-button>

                        <b-button class="btn-title-action dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown">
                            {{ $t(selectedText) }}
                            <DropdownIcon class="dropdown-icon" />
                        </b-button>

                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" :class="{ 'active': selectedFilter == '1' }" href="javascript:void(0)"
                                @click="changeFilterDays('1', 'LogCenter.1Day', 'day')">
                                {{ $t('LogCenter.1Day') }}
                            </a>

                            <a class="dropdown-item" :class="{ 'active': selectedFilter == '5' }" href="javascript:void(0)"
                                @click="changeFilterDays('5', 'LogCenter.5Days','day')">
                                {{ $t('LogCenter.5Days') }}
                            </a>

                            <a class="dropdown-item" :class="{ 'active': selectedFilter == '15' }" href="javascript:void(0)"
                                @click="changeFilterDays('15', 'LogCenter.15Days','day')">
                                {{ $t('LogCenter.15Days') }}
                            </a>

                            <a class="dropdown-item" href="javascript:void(0)"
                                :class="{ 'active': selectedFilter == 'period' }">
                                <div class="date-picker-modal-filter-orders">
                                    <span>
                                        {{ $t('LogCenter.Custom') }}
                                    </span>

                                    <FormPicker class="dt-picker-component" type="data" mode="range"
                                        :minDate="''" :maxDate="new Date()" :defaultDate="[]" :position="'left'" @input="
                                            (value) =>
                                                updateDate(
                                                    value
                                                )
                                        " />
                                </div>
                            </a>

                            <a class="dropdown-item" :class="{ 'active': selectedFilter == 'all' }" href="javascript:void(0)"
                                @click="changeFilterDays('3000', 'LogCenter.AllHistoric', 'all')">
                                {{ $t('LogCenter.AllHistoric') }}
                            </a>
                        </div>
                    </div>
                </div>

                <div class="graph-buttons-resources">
                    <button type="button" :class="[
                        'btn-graph-resources',
                        showPipelines
                            ? 'btn-active'
                            : 'btn-disabled',
                    ]" @click="() => {showPipelines = true; this.getIntegrationLog()}"> Pipeline </button>

                    <button type="button" :class="[
                        'btn-graph-resources',
                        showPipelines
                            ? 'btn-disabled'
                            : 'btn-active',
                    ]" @click="() => {showPipelines = false; this.getServiceLog()}">SHIR</button>
                </div>
                <b-col cols="12">
                    <integration-log v-if="showPipelines" :listagem="listagemIntegration" @atualizaListagemIntegration="getIntegrationLog"/>
                    <service-log v-else :listagem="listagemLog" />
                </b-col>
            </div>
    </div>
</template>

<script>
import Breadcrumb from '../../../../layouts/components/Breadcrumb.vue'
import { BRow, BCol, BButton, BFormDatepicker} from 'bootstrap-vue'
import ServiceLog from './ServiceLog.vue'
import IntegrationLog from './IntegrationLog.vue'
import ReloadIcon from '@/assets/images/pages/Reload.svg'
import DropdownIcon from '@/assets/images/icons/dropdown-icon.svg'
import FormPicker from '@/@core/components/form-picker/FormPicker.vue'
import { mapMutations } from "vuex";

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BFormDatepicker,
        ReloadIcon,
        ServiceLog,
        IntegrationLog,
        Breadcrumb,
        DropdownIcon,
        FormPicker,
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            selectedFilter: "1",
            selectedText: "LogCenter.1Day",
            settings: '',
            active: null,
            filterDate: '',
            showPipelines: true,
            filtersList: {
                filterDays: 1,
                startPeriod: '',
                endPeriod: '',
                allPeriod: 0,
            },
            intervalFunction: 0,
            listagemLog:[],
            listagemIntegration:[],
            refreshing: false,
        }
    },
    created() {
        this.getSettings(),
        this.getServiceLog();
        this.getIntegrationLog();
        this.atualizaDados()
    },
    destroyed() {
        clearInterval(this.intervalFunction);
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        refreshData(){
            this.getServiceLog();
            this.getIntegrationLog();
        },
        atualizaDados() {
            let timeRefreshSeconds = 15000
            this.intervalFunction = setInterval(() => {
                this.getServiceLog();
                this.getIntegrationLog();

            }, timeRefreshSeconds);
        },
        getServiceLog() {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.listagemLog = []
            this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/service-log?site_guid=${this.currentSite.guid}&filterDays=${this.filtersList.filterDays}&startDate=${this.filtersList.startPeriod}&endDate=${this.filtersList.endPeriod}&allPeriod=${this.filtersList.allPeriod}`)
                .then(res => { this.listagemLog = res.data.response; this.refreshing = false; this.UPDATE_FLAG_SHOW_OVERLAY(false); })
        },
        getIntegrationLog() {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.listagemIntegration = []
            this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/event_script/pipelines/log?site_guid=${this.currentSite.guid}&filterDays=${this.filtersList.filterDays}&startDate=${this.filtersList.startPeriod}&endDate=${this.filtersList.endPeriod}&allPeriod=${this.filtersList.allPeriod}`)
                .then(res => { this.listagemIntegration = res.data; this.refreshing = false; this.UPDATE_FLAG_SHOW_OVERLAY(false); })
        },
        getSettings() {
            this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/setting?site_guid=${this.currentSite.guid}`)
                .then(res => { this.settings = res.data.Settings; this.checkIfClientIsActive() })
        },
        checkIfClientIsActive() {
            let now = new Date();
            let lastContact = this.settings.client_last_contact ? new Date(this.settings.client_last_contact) : null

            if (!lastContact)
                return this.active = false

            return this.active = ((now - lastContact) / 1000) > 120 ? false : true
        },
        changeFilterDays(filter,text, type) {
            this.selectedFilter = filter;
            this.selectedText = text;
            this.clearFilters()
            if(type == 'day'){
                this.filtersList.filterDays = filter
            }
            else if(type == 'custom'){
                this.filtersList.startPeriod = filter.substring(0,10)
                this.filtersList.endPeriod = filter.substring(14,24)
                
            }else{
                this.filtersList.allPeriod = 1
            }
            this.getServiceLog();
            this.getIntegrationLog();
        },
        clearFilters(){
            this.filtersList.startPeriod = ''
            this.filtersList.endPeriod = ''
            this.filtersList.filterDays = ''
            this.filtersList.allPeriod = 0
        },
        updateDate(value) {
            if (value != this.filterDate) {
                $("#dropdownMenuButton").dropdown('toggle');
            }
            this.filterDate = value;
            this.changeFilterDays(value ,'LogCenter.Custom', 'custom');
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.flatpickr-calendar {
    right: 246px !important;
    top: 215px !important;

    .flatpickr-day {

        &.startRange,
        &.endRange {
            background-color: #974900 !important;
            border-color: #974900 !important;
            color: #ffff !important;
        }

        &.inRange {
            box-shadow: -5px 0 0 #ECE0DB, 5px 0 0 #ECE0DB;
            background-color: #ECE0DB !important;
            border-color: #ECE0DB !important;
            color: #6e6b7b !important;

            &:hover {
                box-shadow: -5px 0 0 #ECE0DB, 5px 0 0 #ECE0DB;
            }
        }

        &.today {
            border-color: #974900;
        }
    }
}

.tooltip-info-log-center {
    //não adicione padding aqui e só estilize as classes especificas se possível
    margin: 0;
    .tooltip-inner {
        padding: 0;
        max-width: 100%;
        min-width: 1%;
        border-radius: 6px;
        overflow: hidden;

        .info-tooltip-log-center {
            max-width: 467px;
            padding: 7px 8px;
            text-align: left;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #fff;
            letter-spacing: 0.16px;
            border: 1px solid transparent;
        }
    }

    .arrow {
        &::before {
            border-top-color: #323232;
            border-bottom-color: #323232;
        }
    }
}

@media (max-width: 480px) {
    .tooltip-log-center {
        .info-tooltip-log-center {
            max-width: 288px !important;
        }
    }

    .card-log-center-title {
        flex-direction: column;
        align-items: flex-start !important;
    }

    .card-title-actions {
        flex-direction: column;
        margin-top: 1rem;
        width: 100%;
        gap: 9px;

        .btn-title-action {
            justify-content: center;
        }

        .dropdown-menu {
            width: 100%;
        }
    }
}

@media (max-width: 572px) {
    .flatpickr-calendar {
        position: fixed !important;
        right: inherit !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%);
    }
}

.log-center-section {
    .card-log-center {
        background: #FFFFFF;
        box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
        border-radius: 6px;

        .card-header-log-center {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px;
            gap: 10px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }

        .card-log-center-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;

            span {
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                color: #4C4541;
            }
        }

        .card-title-actions {
            position: relative;
            display: flex;
            justify-content: center;
            gap: 14px;

            .btn-title-action {
                padding: 7px 14px;
                display: flex;
                align-items: center;
                background-color: #FFFFFF !important;
                color: #974900 !important;
                border-radius: 6px;
                border: 1px solid #974900 !important;
                font-size: 12px;
                font-weight: 600;

                .reload-icon {
                    margin-right: 6px;
                }

                .dropdown-icon {
                    margin-left: 6px;
                }

                &:hover {
                    background-color: #ffede1 !important;
                    box-shadow: none !important;
                }

                &.reload {
                    svg {
                        margin-right: 7px;
                    }
                }

                &:focus {
                    background-color: transparent !important;
                }
            }

            .dropdown-toggle {
                &::after {
                    content: none !important;
                }
            }

            .dropdown-menu {
                min-width: 214px !important;

                .dropdown-item {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #998F8A;

                    &:hover {
                        background-color: #FFEDE2 !important;
                        color: #974900 !important;

                        span {
                            color: #974900 !important;
                        }
                    }

                    &:active {
                        background-color: #FFEDE2 !important;
                        color: #974900 !important;
                    }
                }

                .dropdown-item.active {
                    color: #FFFFFF !important;
                    background-color: #974900 !important;

                    span {
                        color: #FFFFFF !important;
                    }
                }

                .date-picker-modal-filter-orders {
                    position: relative;
                    width: 100% !important;
                    height: 100% !important;

                    fieldset {
                        height: 100%;

                        div {
                            height: 100%;

                            input {
                                height: 100%;
                            }
                        }
                    }

                    span {
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #998F8A;
                    }

                    input {
                        position: relative;
                        z-index: 2;
                        color: transparent;
                        border: none;
                        cursor: pointer;

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    input.form-control {
                        padding: 0.2rem !important;
                    }

                    .form-group {
                        margin-bottom: 0 !important;
                    }

                    svg {
                        display: none;
                    }

                    .dt-picker-component {
                        height: 100%;
                    }
                }
            }
        }

        .graph-buttons-resources {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;

            .btn-graph-resources {
                font-size: 16px;
                border: none;
            }

            .btn-active {
                border-bottom: 2px solid #974900;
                font-weight: 600;
                width: 100%;
                background-color: transparent !important;
                color: #974900 !important;
                border-radius: 0;
            }

            .btn-disabled {
                border-bottom: 2px solid #cfc4be;
                width: 100%;
                background-color: transparent !important;
                color: #cfc4be !important;
                border-radius: 0;
            }
        }

        .input-search {
            margin-bottom: 8px !important;
        }

        #table-default thead th {
            border-top: 1px solid #ebe9f1 !important;
            border-bottom: 0 !important;
            border-left: 0 !important;
            border-right: 0 !important;
            text-transform: uppercase !important;
        }
    }
}
</style>
