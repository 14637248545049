<template>
    <div class="card-integration-log">
        <div>
            <table-default :rows="listagemIntegration" :fields="fields" :stickyHeader="'1440px'" :striped="true" class="table-integration-log">
                <template v-slot:cell(action)="data">
                    <div >
                        <div 
                        @click="finishLog(data.item.id)" 
                        :class="['btn-delete', (data.item.status != 'Running' && data.item.status != 'Scheduled') && 'disabled' ]"
                         :id="`tooltip-clean-site-${data.index}`">
                            <RemoveIcon class="icon" />
                        </div>

                        <b-tooltip triggers="hover blur" :target="`tooltip-clean-site-${data.index}`" positioning="top"
                            custom-class="tooltip-finish" placement="top" boundary-padding="0" noninteractive>
                            <div class="info-tooltip-log-center">
                                {{ $t('IntegrationPage.Finish') }}
                            </div>
                        </b-tooltip>
                    </div>

                </template>

                <template v-slot:cell(status)="data">
                    <div class="text-center col-spinner">
                        <SpinnerIcon v-if="data.item.status == 'Running'" class="spinner-icon" />

                        <b-badge v-else :variant="statusVariant(data.item.status)">
                            {{ data.item.status }}
                        </b-badge>
                    </div>

                </template>

                <template v-slot:cell(start_date)="data">
                    {{ getFormatedDate(data.item.start_date) }}
                </template>

                <template v-slot:cell(finish_date)="data">
                    {{ getFormatedDate(data.item.finish_date) }}
                </template>

                <template v-slot:cell(pipeline)="data">
                    <div class="add-ellipsis">
                        <span :id="`tooltip-connection-pipeline-${data.index}`">
                            {{ data.item.pipeline }}
                        </span>
                    </div>

                    <b-tooltip custom-class="tooltip-info-log-center" :target="`tooltip-connection-pipeline-${data.index}`" triggers="hover blur" positioning="top"
                        placement="top" boundary-padding="0">
                        <div class="info-tooltip-log-center">
                            {{ data.item.pipeline }}
                        </div>
                    </b-tooltip>
                </template>

                <template v-slot:cell(event_script)="data">
                    <div class="add-ellipsis">
                        <span :id="`tooltip-connection-activity-${data.index}`">
                            {{ data.item.event_script }}
                        </span>
                    </div>

                    <b-tooltip custom-class="tooltip-info-log-center" :target="`tooltip-connection-activity-${data.index}`" triggers="hover blur" positioning="top"
                        placement="top" boundary-padding="0">
                        <div class="info-tooltip-log-center">
                            {{ data.item.event_script }}
                        </div>
                    </b-tooltip>
                </template>

                <template v-slot:cell(log_truncate)="data">
                    <div class="add-ellipsis">
                        <span :id="`tooltip-pipeline-message-${data.index}`">
                            {{ data.item.log_truncate }}
                        </span>
                    </div>

                    <b-tooltip custom-class="tooltip-info-log-center" :target="`tooltip-pipeline-message-${data.index}`" triggers="hover blur" positioning="top"
                        placement="top" boundary-padding="0">
                        <div class="info-tooltip-log-center">
                            {{ data.item.log_truncate }}
                        </div>
                    </b-tooltip>
                </template>

            </table-default>
        </div>
    </div>
</template>

<script>
import {
    BRow, BFormGroup, BFormInput, BCard, BSidebar, VBToggle, BButton, BSpinner, VBModal, BCardText, BTooltip, BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TableDefault from "@core/components/table-default/TableDefault.vue";
import RemoveIcon from "@/assets/images/icons/remove-circle-icon.svg";
import SpinnerIcon from "@/assets/images/icons/spinner.svg";
import moment from "moment";

export default {
    components: {
        BRow,
        BFormGroup,
        BFormInput,
        BCard,
        BTooltip,
        BBadge,
        ToastificationContent,
        BSidebar,
        BButton,
        BSpinner,
        VBModal,
        TableDefault,
        BCardText,
        RemoveIcon,
        SpinnerIcon
    },
    directives: {
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple,
    },
    data() {
        return {
            listagemIntegration:[],
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            pageLength: 10,
            dir: false,
            connectionId: '',
            fields: [
                {
                    label: 'IntegrationPage.Actions',
                    key: 'action',
                    sortable: false,
                },
                {
                    label: 'ID',
                    key: 'id',
                    sortable: true
                },
                {
                    label: 'STATUS',
                    key: 'status',
                    sortable: true,
                    thStyle: { width: "6%" }
                },
                {
                    label: 'PIPELINE',
                    key: 'pipeline',
                    sortable: true,
                    thStyle: { width: "12%" }
                },
                {
                    label: 'LogCenter.Activity',
                    key: 'event_script',
                    sortable: true,
                    thStyle: { width: "12%" }
                },
                {
                    label: 'IntegrationPage.StartDate',
                    key: 'start_date',
                    sortable: true,
                    thStyle: { width: "10%" }
                },
                {
                    label: 'IntegrationPage.FinishDate',
                    key: 'finish_date',
                    sortable: true,
                    thStyle: { width: "10%" }
                },
                {
                    label: 'IntegrationPage.Seconds',
                    key: 'seconds',
                    sortable: true,
                },
                {
                    label: 'IntegrationPage.Message',
                    key: 'log_truncate',
                    sortable: true,
                    thStyle: { width: "0%" }
                },
            ],
            rows: [],
            searchTerm: '',
            refreshing: false,
            errorMessage: '',
            intervalFunction: 0
        }
    },
    props: {
        listagem: {
            type: Number,
        },
    },
    watch:{
        listagem: function(newVal, oldVal){
            this.listagemIntegration = newVal
        }
    },  
    methods: {
        getFormatedDate(date) {
            if (date) {
                let language = localStorage.getItem('language');

                if (language == 'pt')
                    return moment(date).format('DD-MM-YYYY HH:mm:ss');
                else
                    return moment(date).format('MM-DD-YYYY HH:mm:ss');
            }

            return '';
        },
        openMessageModal(message) {
            this.errorMessage = message
        },
        finishLog(id) {
            this.$swal({
                title: this.$t('IntegrationPage.attention'),
                text: this.$t('LogCenter.FinishAlert'),
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: this.$t('MySites.cancel'),
                confirmButtonText: this.$t('IntegrationPage.Finish'),
                customClass: {
                    container: 'swal-conection',
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.put(`/api/${this.$router.currentRoute.params.prefix}/event_script/pipelines/end-log/${id}`)
                    .then(res => { this.$emit('atualizaListagemIntegration');   })
                    }
            })
        },
        statusVariant(status) {
            let variant
            switch (status) {
                case 'Scheduled':
                    variant = 'warning'
                    break;
                case 'Integrated':
                    variant = 'success'
                    break;
                case 'Not Integrated':
                    variant = 'danger'
                    break;
                case 'Integrated with erros':
                    variant = 'secondary'
                    break;
            }
            return variant
        },
        showToast(title, icon, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: icon,
                    text: text,
                    variant,
                },
            })
        },
    },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';

.tooltip-finish {
    z-index: 1039 !important;
}

@media (max-width: 768px) {
    .tooltip-finish {
        display: none !important;
    }
}


.table-responsive{
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px !important;               /* width of the entire scrollbar */
        border-radius: 50%;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #FBEEE8;   
        
      }
      &::-webkit-scrollbar-thumb {
        background-color: #CFC4BE;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        border: 10px solid transparent
      }
}
.swal-conection {
    .swal2-modal {
        padding: 0 !important;
        border-radius: 6px;
        width: 710px;

        .swal2-header {
            background-color: #D32F2F;
            padding: 20px 16px;
            border-radius: 6px 6px 0px 0px;
            flex-direction: row;
            justify-content: left;

            .swal2-title {
                color: #ffff;
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                margin: 0;
            }

            .swal2-close {
                border-radius: 6px;
                width: 34px;
                background-color: #ffff;
                height: 34px;
                top: -5px;
                position: absolute;
                right: -5px;
            }
        }

        .swal2-content {
            padding: 20px 16px;

            .swal2-html-container {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #4C4541;
                display: flex !important;
                text-align: left !important;
            }
        }

        .swal2-actions {
            padding: 0 16px 20px 16px;
            display: flex;
            justify-content: right;

            .swal2-confirm {
                margin-left: 10px !important;
                order: 2;
                font-weight: 600;
                font-size: 17px;
                line-height: 20px;
                color: #ffff;
                border-color: #974900 !important;
                background-color: #974900 !important;
                border-radius: 5px;

                &:hover {
                    box-shadow: none !important;
                }
            }

            .swal2-cancel {
                order: 1;
                font-weight: 600;
                font-size: 17px;
                line-height: 20px;
                color: #974900 !important;
                border-radius: 5px;
                border-color: transparent !important;

                &:hover {
                    background-color: #ffede1 !important;
                }
            }
        }
    }
}

.card-integration-log {
    .btn-delete {
        width: 32px;
        height: 28px;
        border-radius: 5px !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: none !important;
        background-color: transparent !important;
    }

    .btn-delete:hover {
        cursor: pointer;
        box-shadow: none !important;
        background-color: #ffdbc4 !important;
    }

    .btn-delete:focus {
        background-color: #ffdbc4 !important;
    }

    .btn-delete:active {
        background-color: #ffdbc4 !important;
    }

    .btn-delete.disabled {
        background-color: #ECE0DB !important;
        pointer-events: none;

        &:hover {
            background-color: #ECE0DB !important;
        }

        &:focus {
            background-color: #ECE0DB !important;
        }

        &:active {
            background-color: #ECE0DB !important;
        }

        .icon {
            filter: invert(48%) sepia(4%) saturate(878%) hue-rotate(337deg) brightness(95%) contrast(86%)
        }
    }

    .badge-success {
        color: #4CAF50 !important;
        background: #E8F5E9 !important;
        border-radius: 4px;
    }

    .badge-danger {
        color: #F44336 !important;
        background: #FFEBEE !important;
        border-radius: 4px;
    }

    .badge-warning {
        background: #EDE7F6 !important;
        color: #673AB7 !important;
    }

    .badge-secondary {
        background: #FFF3E0 !important;
        color: #FF9800 !important;
    }

    .pagination-table {
        margin-bottom: 15px !important;
    }

    .add-ellipsis {
        white-space: nowrap;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .col-spinner{
        display: flex;
        justify-content: center;
        .spinner-icon {
            -webkit-animation: spin 1.5s linear infinite;
            -moz-animation: spin 1.5s linear infinite;
            animation: spin 1.5s linear infinite;
            animation-play-state: running !important;
            margin: auto 0 !important;
        }
    }
    

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .table-integration-log {
        table {
            thead {
                th {
                    padding: 10.5px 7px !important;
                }
            }

            td {
                padding: 14.5px 7px !important;
                height: 38px !important;
                letter-spacing: 0;
                border: 1px solid #E0E0E0 !important;
                border-bottom: none !important;
                border-left: none !important;
                border-right: none !important;
            }

            td:nth-child(1) {
                text-align: center !important;
            }
            
            th:nth-child(1) {
                min-width: 57px !important;
                width: 57px !important;
            }

            th:nth-child(2) {
                min-width: 96px !important;
                width: 96px !important;
            }

            th:nth-child(3) {
                min-width: 157px !important;
                width: 157px !important;
            }
            
            th:nth-child(4),
            td:nth-child(4),
            th:nth-child(5),
            td:nth-child(5),
            th:nth-child(9),
            td:nth-child(9) {
                min-width: 232.67px !important;
                width: 232.67px !important;
            }

            th:nth-child(6),
            th:nth-child(7) {
                min-width: 163px !important;
                width: 163px !important;
            }
            td:nth-child(6),
            td:nth-child(7) {
                padding-right: 0 !important;
            }

            th:nth-child(8) {
                min-width: 107px !important;
                width: 107px !important;
            }
        }
    }
}
</style>

